import { i18n } from "@cloposcom/libs";
import * as React from "react";
import { useEffect, useState } from "react";
import loadingSvg from "../assets/svg/loading.svg";
import { AnimatedArrows } from "../components/AnimatedArrows/AnimatedArrows";
import { BlockButton } from "../components/Button";
import { ButtonSelect } from "../components/ButtonSelect";
import { FullPage } from "../components/FullPage";
import { Header } from "../components/Header";
import {
    RedBox,
    Tip,
    Tipcontent,
    TipcontentHeader,
    TipcontentParagraph,
    TipIcons,
    TotalAmount,
} from "../components/Info";
import { Rate } from "../components/misc";
import { api } from "../utils/api";
import { usePaymentData } from "../utils/hooks";
import { SuccessPage } from "./SuccessPage";
import dinnerPlate from "../assets/svg/dinnerPlate.svg";
import start from "../assets/svg/star.svg";
import arrowRight from "../assets/svg/arrowRight.svg";
import { motion } from "framer-motion";
import TipModal from "../components/modal/TipModal";
import { getRateUrl } from "../utils/utils";
import { Divider } from "../components/Divider";

export const PaymentPage: React.FC = () => {
    const { state } = usePaymentData();
    const [tip, setTip] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [input, setInput] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [index, setIndex] = useState<any>();

    const referrer = localStorage.getItem("referrer");

    useEffect(() => {
        setTip(state.tips?.[1].value);
        setIndex(1);
    }, [state.tips?.length]);

    useEffect(() => {
        const handleBackButton = (event: any) => {
            event.preventDefault();
            goBack();
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    const goBack = () => {
        window.history.back();
    };

    if (["loading", "idle", "error"].includes(state.status)) {
        return (
            <FullPage loading={state.status !== "error"} error={state.status === "error"}>
                {state.message}
            </FullPage>
        );
    }

    if (state.status === "success" && state.paymentStatus) {
        return <SuccessPage state={state} />;
    }

    const currency = state.brand?.currency?.toUpperCase();

    const onPayClick = async () => {
        setIsLoading(true);
        api.register({
            venue_id: state.data.venue_id,
            receipt_cid: state.data.receipt_cid,
            waiter_id: state.data.waiter_id,
            total: state.data.total,
            tip: tip,
            hash: state.data.hash,
        })
            .then(res => {
                if (res.data.success) {
                    window.location.href = res.data.data.url;
                } else {
                    setIsLoading(false);
                    alert(`${i18n.cLang().error}: ${res.data.message}`);
                }
            })
            .catch(err => {
                setIsLoading(false);
                alert(`${i18n.cLang().error}: ${err.message}`);
            });
    };

    let icon = getIcon(tip);

    function getIcon(tip) {
        const i = state?.tips?.findIndex(t => tip <= t.value);
        if (i == -1) {
            return require("../assets/img/suprice.png");
        }
        if (i === 0) {
            return require("../assets/img/unamused-face.png");
        } else if (i === 1) {
            return require("../assets/img/smiling-face.png");
        } else if (i === 2) {
            return require("../assets/img/clapping-hands.png");
        } else if (i === 3) {
            return require("../assets/img/suprice.png");
        }
    }

    const spring = {
        type: "spring",
        damping: 5,
        stiffness: 100,
        ease: "elastic",
    };

    return (
        <div className="App">
            <Header
                goBack={goBack}
                referrer={referrer}
                logo={state?.brand.logo?.urls?.logo ?? state?.brand.logo?.urls?.original}
                title={state.brand.brand_name}
            />
            <Divider />

            <TotalAmount>
                <div style={{ fontSize: "18px" }}>{i18n.cLang().subtotal}:</div>
                <span
                    style={{
                        color: "#1C6EEB",
                        fontSize: "24px",
                    }}
                >
                    {state?.data?.total?.toFixed(2)} ₼
                </span>
            </TotalAmount>

            <Tip>
                <img src={dinnerPlate} alt="" />
                <Tipcontent>
                    <TipcontentHeader>{i18n.cLang().tip_for_waiter}</TipcontentHeader>
                    <TipcontentParagraph>{i18n.cLang().tip_description}</TipcontentParagraph>
                </Tipcontent>
            </Tip>

            {state.data.declined ? <RedBox>Your payment declined, but you can still try again.</RedBox> : null}

            <ButtonSelect
                writtenTip={input}
                open={openModal}
                setOpen={setOpenModal}
                options={state.tips}
                active={tip}
                activeIndex={index}
                currency={currency}
                onChange={(val, indexVal) => {
                    setIndex(indexVal);
                    setTip(val);
                    if (input) {
                        setInput("");
                    }
                }}
            />

            <TipModal
                total={state?.data?.total}
                input={input}
                setTip={setTip}
                setInput={setInput}
                open={openModal}
                setOpen={setOpenModal}
            />

            <TipIcons id="tip-icon">
                <motion.div
                    className="rate-icon"
                    key={tip}
                    transition={spring}
                    initial={{ opacity: 1, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                >
                    <img style={{ width: "auto", height: "100%" }} src={icon} />
                </motion.div>
            </TipIcons>

            <footer>
                <BlockButton
                    disabled={isLoading}
                    style={{ fontSize: 22, position: "relative", marginBottom: "10px" }}
                    onClick={onPayClick}
                >
                    {!isLoading && <AnimatedArrows dir="Right" count={4} style={{ position: "absolute", left: 15 }} />}
                    {isLoading && (
                        <img
                            src={loadingSvg}
                            alt=""
                            style={{
                                height: 30,
                                position: "absolute",
                                left: 20,
                            }}
                        />
                    )}

                    <span style={{ marginRight: 8, fontWeight: 100 }}>{i18n.cLang().pay}</span>
                    <strong>
                        {(state?.data?.total + tip)?.toFixed(2)} ₼ {/* {currency} */}
                    </strong>
                    {!isLoading && <AnimatedArrows dir="Left" count={4} style={{ position: "absolute", right: 15 }} />}
                </BlockButton>
                {/*<VersionFooter>*/}
                {/*    version {parseInt(process.env.REACT_APP_BUILD_NUMBER) || "--dev"}*/}
                {/*</VersionFooter>*/}
            </footer>
        </div>
    );
};

const Htag = {
    margin: "0 0 3px 0",
    padding: "0",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#344255",
};
const ptag = {
    margin: "0",
    padding: "0",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#64748B",
};
