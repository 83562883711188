import axios from "axios";
import { getDataFromLocation } from "./utils";

export const api = {
    brand: "",
    stage: "",
    brandUrl: (path: string) =>
        `https://${api.brand}.clopos.com/api${api.stage === "prod" ? "" : api.stage ? "-" + api.stage : ""}/${path}`,
    getBrandStageAndName: async (id: number) => {
        const data = await axios.get(`https://api.clopos.com/api/service/brand-id/${id}`).then(data => data.data);

        if (!data.success) {
            throw new Error("Brand not found");
        }

        return {
            name: data.data.name,
            stage: data.data.stage,
        };
    },
    getBrandData: () => {
        const loc = getDataFromLocation();

        return axios.get(api.brandUrl("check"), {
            headers: {
                "X-Venue": loc.venue_id,
            }
        }).then(data => data.data);
    },
    checkPaymentStatus: (receipt_cid: string) => {
        const loc = getDataFromLocation();

        return axios.post(api.brandUrl("pay/check-status"), { receipt_cid }, {
            headers: {
                "X-Venue": loc.venue_id,
            }
        });
    },
    register: (data: any) => {
        const loc = getDataFromLocation();

        return axios.post(api.brandUrl("pay/register"), data, {
            headers: {
                "X-Venue": loc.venue_id,
            }
        });
    },
};
